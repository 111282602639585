import React from 'react'

export default function AboutUsPage () {
  return(
    <div className="about-us-page">

        <div className="landing-page-wrapper">
          <div className="landing-page">

              <div className="about-us">
                <div className="section-header">
                <div className="text">
                    <p className="decorative">Get to know</p>
                  <h2>About Us</h2>
                </div>
                  {/* <Link to="/order" className="order-online-link">Order Now</Link> */}
                </div>

                <div className="content">
                  <h3>Our Story</h3>
                  <p>It all started with a Chef who had always aspired to advance his dream and own his own restaurant – where he could house his famous dish “Enchiladas Rojas y Verdes.”</p>
                  <p>MexCocina Cafe offers authentic Mexican and American cuisines. It’s simple – we prepare our food from scratch with fresh, quality and traditional ingredients that will cater to your tastes and make your tummy smile! Our gourmet entrees, fresh salads and refreshing drinks are perfect for your leisurely meal with friends and/or family – we want you to feel right at home! Our homey atmosphere is a lovely place to settle in for any occasion and any time of the day -breakfast, lunch or dinner! We love great food and we want to share the MEXICAN experience with you!</p>
                  <h3>Meet Isaias & Yammineth</h3>
                  <p><img src="https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1544212860/danstuff/joint-768x576.jpg" alt=""/></p>
                  <p>The concept of opening up a family-oriented business had always been a part of Isaias and Yammineth’s ambition. With a shared desire to open a restaurant and share the MEXICAN experience, they made it happen! Isaias and Yammineth strives to provide and accommodate every guest that enters MexCocina Café a delectable, comforting and pleasant stay. MexCocina will allow Burbank residents to experience the Mexican-American fusion tradition!</p>
                  <p>Isaias loves to cook, while Yammineth enjoys socializing with family, friends and guests!</p>
                </div>
              </div>
          </div>
      </div>
    </div>
  )
}
