import React from 'react'
// import emoji from '../react-easy-emoji'
// import {CloudinaryContext, Image, Transformation} from 'cloudinary-react'
import PropTypes from 'prop-types'
import CartAddToCartButton from './CartAddToCartSimpleButton'
import MagGlass from './MagGlassIcon'
import makeCloudinaryUrlForPhoto from './makeCloudinaryUrlForPhoto'
import {Link} from 'react-router-dom'



let MAX_ITEMS = 50

class MenuCategoryListView extends React.Component {

  state = {
    showAll: true
  }
  showRestOfCategory= () => {
    this.setState({ showAll: true })
  }
  render() {

    let className="photos grid"
    let isGallery = false
    let items = this.props.items
    let disableOrder = this.props.disableOrder
    let noPhoto = this.props.noPhoto
    let altStyleA = this.props.altStyleA
    

    let tagsCss = this.props.tags ? (' ' + this.props.tags) : ''
    let itemsToDisplay = this.state.showAll ? items : items.slice(0,MAX_ITEMS)
    return (
      <div className="menu-category-list-view">

        {/* List/Grid of Items */}
        <div className={className + tagsCss}>
          {itemsToDisplay.map(
            i => <MenuItem isGallery={isGallery}
              disableOrder={disableOrder}
              altStyleA={altStyleA}
              key={i.id} {...i}
              noPhoto={noPhoto}
              openPhotoSwipeGallery={this.props.openPhotoSwipeGallery}
              width={this.props.width}></MenuItem>)}
        </div>



        </div>
    );
  }
}

export default MenuCategoryListView

class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // console.log(name)
    let {altStyleA, noPhoto, id, name, photo, width='600', description, mandatory, price, originalPrice, modifiers } = this.props
    console.log('noPhoto', noPhoto)

    name = name || {}
    let chinese = name.zh
    let korean  = name.kr
    name = (name && name.en) || "untitled"
    description = description && description.en

    let cloudinaryURL
    if (photo) {
      cloudinaryURL = makeCloudinaryUrlForPhoto(photo, {width})
    }

    let dineInOnly = (name.indexOf('Dine-In Only') !== -1)
    let disableOrder = this.props.disableOrder || dineInOnly
    
    if (altStyleA) {
      if (photo) {
        cloudinaryURL = makeCloudinaryUrlForPhoto(photo, {height: 300})
      }
      return(
        <section
            className="menu-item"
            >

        <Link
            className="item-link"
            aria-label={`customize ${name} and add to cart`}
            title={`customize ${name} and add to cart`}
            to={disableOrder ? "#" : (`/products/${this.props.id}`)}><div className="content">
        <header>
          <span
            id={'p_' + id}
            className="food-name">{name}</span>
        </header>
        <p className="menu-item__description">
          {korean ? <span>{korean} ·</span> : ''} 
          {description}</p>
          <div className="item-price">${price}</div>
      </div>
      </Link>
        

        {cloudinaryURL && !noPhoto && <div
          className="photo-container"
          onClick={this.props.openPhotoSwipeGallery.bind(this, id)}>
            <img
              src={cloudinaryURL}
              alt={""}
            />
            <MagGlass></MagGlass>
        </div>}
      </section>
      )
    }

    return (

        <section className="menu-item">
          {cloudinaryURL && !noPhoto && <div
            className="photo-container"
            onClick={this.props.openPhotoSwipeGallery.bind(this, id)}>
              <img
                src={cloudinaryURL}
                alt={""}
              />
              <MagGlass></MagGlass>
          </div>}
        <div className="content">
          <header>
            <span
              id={'p_' + id}
              className="food-name">{name}</span>
          </header>
          <p className="menu-item__description">
            {korean ? <span>{korean} ·</span> : ''} 
            {description}</p>
        </div>
        <div className="all-prices">
       {originalPrice ? <div className="discount-price">${originalPrice} </div> : null}
          {!disableOrder &&
            <Link
              className="product-link"
              aria-label={`customize ${name} and add to cart`}
              title={`customize ${name} and add to cart`}
              to={`/products/${this.props.id}`}>
          
          
                <span className="plus">+</span> <span className="price">${price}</span>
          
              </Link>}
        </div>
          {disableOrder && <span className="price">${price}</span>}
        </section>

    );
  }
}
