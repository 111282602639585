
import * as Components from "./Taq"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "taq"
}

